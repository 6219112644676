/*==== SCROLL BAR STYLE ====*/
/* width and height */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
 
/* Track */
::-webkit-scrollbar-track { 
    border-radius: 100px;
    background-color: #EBEBEB;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: #B3B3B3; 
    border-radius: 100px;
}
 
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #B3B3B3;
    cursor: pointer;
}